import React from 'react';

import Particles from 'react-particles-js';


const particles = (props) => {
  return (
    <Particles
      
        params = {{
          particles : {
            shape : {
              type: "circle"
            },
            number: {
              value: 100,
              density: {
                enable: true,
                value_area: 800
              }
            },
            size: {
              value: 1
            },
            line_linked : {
              enable: true,
              color: '#00FF00',
              width: 1
            },
            retina_detect : true
          }
        }}

        style ={{
          position : "absolute"
        }}
    />
  );
}

export default particles;