import React from 'react';

import styles from './Splash.module.css'

import MyParticles from '../MyParticles/MyParticles'

const splash = (props) => {
  return (
    <div className={styles.Splash}>      
      <MyParticles />
      <div className={styles['text-container']}>        
        <h1 className={styles.glitch}>The best is yet to come,</h1>
        <h1 className={styles.glitch}>let us help you get there</h1>
      </div>
    </div>    
  );
};

export default splash;