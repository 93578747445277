import React, { Component } from 'react';
import './App.css';

import PageHeader from './components/PageHeader/PageHeader'
// import MyParticles from './components/MyParticles/MyParticles'
import Splash from './components/Splash/Splash'

class App extends Component {
  render() {
    return (
      <div className="App">
        <PageHeader/>
        <Splash/>
      </div>
    );
  }
}

export default App;
