import React from 'react';
import './PageHeader.css'

import logo from './logo-small.png'
import {Navbar, Nav} from 'react-bootstrap'

const pageHeader = (props) => {
  return (
    <header className='PageHeader'>
      <Navbar  expand="lg">
        <Navbar.Brand href="#home">
          <img src={logo} alt='Code Keepers Incorporated Logo' className='PageHeaderLogo'/>
        </Navbar.Brand>
        <Nav.Link href='mailto:darien.martinez@codekeepersinc.com'>Contact us</Nav.Link>
      </Navbar>
    </header>
  );
}

export default pageHeader;